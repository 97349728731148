body {
  margin: 0;
  font-family: 'TeodorLight', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f6f2;
}

p {
  font-family: 'TeodorLight', sans-serif;
  font-weight: normal;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@font-face {
  font-family: 'TeodorLight';
  src: url('./fonts/Teodor-Light.woff2') format('woff2'),
    url('./fonts/Teodor-Light.otf') format('otf');
}

@font-face {
  font-family: 'TeodorThin';
  src: url('./fonts/Teodor-Thin.woff2') format('woff2'),
    url('./fonts/Teodor-Thin.otf') format('otf');
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('./fonts/EuclidCircularB-Regular.woff2') format('woff2'),
    url('./fonts/EuclidCircularB-Regular.otf') format('otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('./fonts/EuclidCircularB-Medium.woff2') format('woff2'),
    url('./fonts/EuclidCircularB-Medium.otf') format('otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('./fonts/EuclidCircularB-Semibold.woff2') format('woff2'),
    url('./fonts/EuclidCircularB-Semibold.otf') format('otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'EuclidCircularBMedium';
  src: url('./fonts/EuclidCircularB-Medium.woff2') format('woff2'),
    url('./fonts/EuclidCircularB-Medium.otf') format('otf');
}

::placeholder {
  color: #c4c4c8;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

/* Remove excess padding and border in Firefox 4+ */
button:-moz-focus-inner {
  border: 0;
  padding: 0;
}
