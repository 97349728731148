input,
textarea,
label,
select {
  outline: none;
}

input:focus,
textarea:focus,
label:focus,
select:focus {
  outline: none;
}
